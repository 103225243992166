// Imports
import { ParametersOrFiles, APICall, INITIATE_API_CALL, InitiateAPICallAction, ThunkResult } from './types';
import commonActions from '../../../foundation/front-end/redux/actions';
import * as Sentry from '@sentry/browser';
import isObject from 'lodash/isObject';
import mapKeys from 'lodash/mapKeys';
import parseMarkdown from '../../../utils/markdown/parse';
import React from 'react';


// Exports
const apiActions = {
	// Store when calls to endpoints were last initiated
	initiateAPICall(endpoint: string, initiated: number): InitiateAPICallAction {
		return {
			type: INITIATE_API_CALL,
			endpoint,
			initiated,
		};
	},
	
	
	// Asynchronous thunk to make an API call
	call<Parameters = undefined, Files = undefined, Response = undefined>(
		options: APICall<Parameters, Files, Response>
	): ThunkResult<Promise<void>> {
		return async (dispatch, getState) => {
			// Return early if pre-rendering
			if (navigator.userAgent === 'ReactSnap') {
				return;
			}
			
			
			// Return early in Storybook
			if ((window as typeof window & { STORYBOOK?: true }).STORYBOOK) {
				return;
			}
			
			
			// Remember when this API call was initiated
			options.initiated = new Date().getTime();
			
			
			// Extract URI for convenience
			const { uri } = options;
			
			
			// Flatten the file object's keys
			let flattenedFiles: {
				[key: string]: File;
			} = {};
			
			if (isObject((options as APICall<Record<string, unknown>, Record<string, unknown>, undefined>).files)) {
				const flattenFiles = (current: ParametersOrFiles, keyString: string) => {
					if (current instanceof window.File) {
						flattenedFiles[`${keyString}`] = current;
					} else if (Array.isArray(current)) {
						for (const [key, value] of current.entries()) {
							flattenFiles(value as ParametersOrFiles, `${keyString}[${key}]`);
						}
					} else if (isObject(current)) {
						for (const [key, value] of Object.entries(current)) {
							flattenFiles(value as ParametersOrFiles, `${keyString}.${key}`);
						}
					}
					
					return current;
				};
				
				flattenFiles((options as APICall<Record<string, unknown>, Record<string, unknown>, undefined>).files, '');
			}
			
			flattenedFiles = mapKeys(flattenedFiles, function (value, key) {
				return key.slice(1);
			});
			
			
			// Error if total file upload size is too big (ie: > 100MB)
			const totalFileUploadSize = Object.values(flattenedFiles).reduce((total, file) => total + file.size, 0);
			
			if (totalFileUploadSize > 100 * 1000 * 1000) {
				// Message to emit regardless of mechanism for handling error
				const message = 'The combined size of the files you’re trying to upload exceeds the limit of 100 MB.';
				
				
				// Handle errors automatically, if needed
				if (options.handleErrorsAutomatically !== false) {
					dispatch(
						commonActions.showPageMessage({
							color: 'danger',
							title: 'Oh snap!',
							message: message,
						})
					);
					
					return;
				}
				
				
				// Send to completion handler otherwise
				options.completion({
					status: 'error',
					code: 'TOTAL_FILE_UPLOAD_TOO_LARGE',
					message: message,
				});
				
				
				// Stop processing
				return;
			}
			
			
			// Create body
			const body = {
				details: {
					action: options.action,
					uri: uri,
				},
				parameters: (options as APICall<Record<string, unknown>, Record<string, unknown>, undefined>).parameters,
			};
			
			
			// Build form data
			const formData = new FormData();
			
			formData.append('body', JSON.stringify(body));
			
			for (const [key, file] of Object.entries(flattenedFiles)) {
				formData.append(key, file);
			}
			
			
			// Determine environment hostname
			let environmentHostname = '';
			
			try {
				const hostnamePieces = window.location.hostname.split('.');
				hostnamePieces.shift();
				environmentHostname = hostnamePieces.join('.');
			} catch (ignore) {
				// Do nothing
			}
			
			
			// Initialize
			const headers = new Headers(options.headers);
			
			const request = new Request(`https://api.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}${uri}`, {
				method: 'POST',
				body: formData,
				cache: 'no-store',
				credentials: options.disableAuthentication
					? 'omit'
					: environmentHostname === process.env.REACT_APP__ENVIRONMENT_HOSTNAME
					  ? 'include'
					  : 'omit',
				headers,
			});
			
			
			// Increment the network request count
			dispatch(commonActions.incrementActiveRequestCount());
			
			
			// Store that we initiated this call
			dispatch(apiActions.initiateAPICall(`${options.action} ${uri}`, options.initiated));
			
			
			// Store breadcrumb
			Sentry.addBreadcrumb({
				category: 'api_call_initiated',
				data: {
					endpoint: `${options.action} ${uri}`,
				},
				level: 'debug',
			});
			
			
			// Make the fetch
			return fetch(request)
				.then((responseObject) => {
					// Decrement the network request count
					dispatch(commonActions.decrementActiveRequestCount());
					
					
					// Don't go any further if this API call should be canceled
					if (options.cancelOnRouteChange !== false) {
						if ((options.initiated || Infinity) < getState().common.route.lastChange) {
							return;
						}
					}
					
					if (options.cancelOnNewerCall === true) {
						if ((options.initiated || Infinity) < getState().api.endpointsInitiated[`${options.action} ${uri}`]) {
							return;
						}
					}
					
					
					// Get text from response
					responseObject
						.text()
						.then((response) => {
							// Resolve if requested
							if (typeof options.callBeforeFinish === 'function') {
								try {
									options.callBeforeFinish();
								} catch (problem) {
									// Log error during development
									if (process.env.NODE_ENV === 'development') {
										console.error(problem);
									}
									
									
									// Send to Sentry
									Sentry.captureException(problem);
									
									
									// Store message
									const message = 'An unexpected issue occurred after retrieving a response from the back end.';
									
									
									// Handle errors automatically, if needed
									if (options.handleErrorsAutomatically !== false) {
										dispatch(
											commonActions.showPageMessage({
												color: 'danger',
												title: 'Oh snap!',
												message: message,
											})
										);
										
										return;
									}
									
									
									// Send to completion handler otherwise
									options.completion({
										status: 'error',
										code: 'BEFORE_FINISH_EXCEPTION',
										message: message,
									});
									
									
									// Stop processing
									return;
								}
							}
							
							
							// Store breadcrumb
							Sentry.addBreadcrumb({
								category: 'api_call_response',
								data: {
									endpoint: `${options.action} ${uri}`,
									response: response,
								},
								level: 'debug',
							});
							
							
							// Handle raw response
							if (typeof options.handleRawResponse === 'function') {
								options.handleRawResponse(response);
							}
							
							
							// Handle reCAPTCHA v2 challenges
							if (responseObject.status === 499) {
								// Throw if no handler was provided
								if (!options.recaptchaV2) {
									throw new Error('reCAPTCHA v2 required by response, but no handler was provided');
								}
								
								
								// Call handler
								options.recaptchaV2();
								
								
								// Stop processing
								return;
							}
							
							
							// Check for a problematic status code
							if (responseObject.status !== 200) {
								// Send to Sentry
								Sentry.captureMessage(
									`HTTP status code ${responseObject.status} for: ${options.action} ${uri}`,
									'error'
								);
								
								
								// Store message
								const message = `HTTP status code: ${responseObject.status}.`;
								
								
								// Handle errors automatically, if needed
								if (options.handleErrorsAutomatically !== false) {
									dispatch(
										commonActions.showPageMessage({
											color: 'danger',
											title: 'Unexpected issue',
											message: message,
										})
									);
									
									return;
								}
								
								
								// Send to completion handler otherwise
								options.completion({
									status: 'error',
									code: 'UNEXPECTED_HTTP_STATUS_CODE',
									message: message,
								});
								
								
								// Stop processing
								return;
							}
							
							
							// Attempt to parse JSON
							let json: SuccessfulAPIResponse<Response> | UnsuccessfulAPIResponse;
							
							try {
								json = JSON.parse(response) as SuccessfulAPIResponse<Response> | UnsuccessfulAPIResponse;
							} catch (problem) {
								// Log error during development
								if (process.env.NODE_ENV === 'development') {
									console.error(problem);
								}
								
								
								// Send to Sentry
								Sentry.captureException(problem);
								
								
								// Store message
								const message = 'An unexpected issue occurred while decoding the back end’s response.';
								
								
								// Handle errors automatically, if needed
								if (options.handleErrorsAutomatically !== false) {
									dispatch(
										commonActions.showPageMessage({
											color: 'danger',
											title: 'Oh snap!',
											message: message,
										})
									);
									
									return;
								}
								
								
								// Send to completion handler otherwise
								options.completion({
									status: 'error',
									code: 'INVALID_JSON',
									message: message,
								});
								
								
								// Stop processing
								return;
							}
							
							
							// Check for bad optional keys
							if (json.status === 'error' && json.code === 'INVALID_OPTIONAL_AUTHENTICATION') {
								// Simply retry without authentication, if it's a public endpoint
								void dispatch(
									apiActions.call({
										...options,
										disableAuthentication: true,
									})
								);
								
								
								// Stop processing
								return;
							}
							
							
							// Check if (re)authentication is necessary
							if (
								json.status === 'error' &&
								(json.code === 'MISSING_AUTHENTICATION' || json.code === 'INVALID_AUTHENTICATION')
							) {
								// Use custom error handling if provided
								if (options.handleMissingOrInvalidAuthentication) {
									const handleMissingOrInvalidAuthenticationError = (problem: unknown) => {
										// Log error during development
										if (process.env.NODE_ENV === 'development') {
											console.error(problem);
										}
										
										
										// Send to Sentry
										Sentry.captureException(problem);
										
										
										// Store message
										const message = 'An unexpected issue occurred after retrieving a response from the back end.';
										
										
										// Handle errors automatically, if needed
										if (options.handleErrorsAutomatically !== false) {
											dispatch(
												commonActions.showPageMessage({
													color: 'danger',
													title: 'Oh snap!',
													message,
												})
											);
											
											return;
										}
										
										
										// Send to completion handler otherwise
										options.completion({
											status: 'error',
											code: 'HANDLE_AUTHENTICATION_EXCEPTION',
											message: message,
										});
									};
									
									try {
										const result = options.handleMissingOrInvalidAuthentication(json.code);
										
										if (result instanceof Promise) {
											result.catch(handleMissingOrInvalidAuthenticationError);
										}
									} catch (problem) {
										handleMissingOrInvalidAuthenticationError(problem);
									}
									
									return;
								}
								
								
								// Determine SSO port (if any)
								let port = '';
								
								if (window.location.port) {
									port = ':3030';
								}
								
								
								// Send to SSO
								window.location.href = `https://sso.${
									process.env.REACT_APP__ENVIRONMENT_HOSTNAME
								}${port}/?return=${encodeURIComponent(window.location.href)}`;
								
								
								// Stop processing
								return;
							}
							
							
							// Handle errors automatically, if needed
							if (options.handleErrorsAutomatically !== false && json.status === 'error') {
								dispatch(
									commonActions.showPageMessage({
										color: 'danger',
										title: 'Oh snap!',
										message: (
											<React.Fragment>
												{json.parameter ? `${json.parameter}: ` : ''}
												<span dangerouslySetInnerHTML={{ __html: parseMarkdown(json.message) }} />
											</React.Fragment>
										),
									})
								);
								
								return;
							}
							
							
							// Attempt to run the completion handler
							try {
								// TypeScript is not smart enough to just run `options.completion()`
								// Therefore, we have to wrap it in these two `if()` statements
								// It will still always run, because any other case is caught by the previous `if()` statement
								if (options.handleErrorsAutomatically === false) {
									options.completion(json);
								} else if (json.status === 'success') {
									options.completion(json);
								} else {
									throw new Error('This case should be impossible to reach');
								}
							} catch (problem) {
								// Log error during development
								if (process.env.NODE_ENV === 'development') {
									console.error(problem);
								}
								
								
								// Send to Sentry
								Sentry.captureException(problem);
								
								
								// Handle errors automatically, always, since the completion handler threw the exception
								dispatch(
									commonActions.showPageMessage({
										color: 'danger',
										title: 'Oh snap!',
										message: 'An unexpected issue occurred while processing a response from the back end.',
									})
								);
							}
						})
						.catch((problem) => {
							// Log error during development
							if (process.env.NODE_ENV === 'development') {
								console.error(problem);
							}
							
							
							// Send to Sentry
							Sentry.captureException(problem);
							
							
							// Store message
							const message = 'An unexpected issue occurred while retrieving a response from the back end.';
							
							
							// Handle errors automatically, if needed
							if (options.handleErrorsAutomatically !== false) {
								dispatch(
									commonActions.showPageMessage({
										color: 'danger',
										title: 'Oh snap!',
										message: message,
									})
								);
								
								return;
							}
							
							
							// Send to completion handler otherwise
							options.completion({
								status: 'error',
								code: 'RESPONSE_RETRIEVAL_EXCEPTION',
								message: message,
							});
						});
				})
				.catch((problem: unknown) => {
					// Decrement the network request count
					dispatch(commonActions.decrementActiveRequestCount());
					
					
					// Store breadcrumb
					Sentry.addBreadcrumb({
						category: 'api_call_failed',
						data: {
							endpoint: `${options.action} ${uri}`,
							problem: String(problem),
						},
						level: 'debug',
					});
					
					
					// Log error during development
					if (process.env.NODE_ENV === 'development') {
						console.error(problem);
					}
					
					
					// Send to Sentry
					Sentry.captureException(problem);
					
					
					// Store message
					const message = 'An unexpected issue occurred while performing this action.';
					
					
					// Handle errors automatically, if needed
					if (options.handleErrorsAutomatically !== false) {
						dispatch(
							commonActions.showPageMessage({
								color: 'danger',
								title: 'Oh snap!',
								message: message,
							})
						);
						
						return;
					}
					
					
					// Send to completion handler otherwise
					options.completion({
						status: 'error',
						code: 'FETCH_EXCEPTION',
						message: message,
					});
				});
		};
	},
};

export default apiActions;
